<template>
    <div class="nofound">
        <div class="nofound-content">
            <h1>404 Not Found</h1>
            <p>抱歉，您访问的页面不存在。</p>
            <!-- 可以添加一个链接让用户返回首页或其他页面 -->
            <el-link type="primary" href="/" target="_blank">返回首页</el-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NotFound'
}
</script>
<style scoped lang="less">
.nofound {
    display: grid;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    line-height: 2;

    p {
        width: 100%;
    }
}
</style>